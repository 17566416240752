import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Empresa } from "../../_model/publico/empresa";
import { Persona } from "../../_model/publico/persona";
import { ListadoPersonasPaginado } from 'src/app/_model/publico/listado_personas_paginado';
import { PersonaInput } from "src/app/_model/publico/persona_input";

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  private url_listar_exportadores = `${environment.HOST}/personas/listar_exportadores`;
  private url_registrar = `${environment.HOST}/personas/registrar`;
  private url_listar_personas = `${environment.HOST}/personas/listar_x_pagina`;
  private url_listar_x_id = `${environment.HOST}/personas/listar_x_id`;

  constructor(private http: HttpClient) { }

  listarExportadores(content: Empresa) {
    return this.http.post<Persona[]>(this.url_listar_exportadores, content);
  }

  registrarPersona(content: Persona[]) {
    return this.http.post<Persona[]>(this.url_registrar, content);
  }
  listarPersonas(content: ListadoPersonasPaginado) {
    return this.http.post<Persona[]>(this.url_listar_personas, content);
  }

  buscarPersona(content: PersonaInput) {
    return this.http.post<Persona>(this.url_listar_x_id, content);
  }
}
