import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { PuertoEntity } from "src/app/_model/operaciones/puerto_entity";
import { Puertos } from "../../_model/operaciones/puertos";
import { ListadoPuertosPaginado } from 'src/app/_model/operaciones/listado_puertos_paginado';

@Injectable({
  providedIn: 'root'
})
export class PuertoService {

  private url_listar = `${environment.HOST}/puertos/listar`;
  private url_listar_por_pagina = `${environment.HOST}/puertos/listar_por_pagina`;
  private url_registrar = `${environment.HOST}/puertos/registrar`;

  constructor(private http: HttpClient) { }

  listarPuertos() {
    return this.http.post<Puertos[]>(this.url_listar, null);
  }

  listarPuertosPaginado(content: ListadoPuertosPaginado) {
    return this.http.post<Puertos[]>(this.url_listar_por_pagina, content);
  }


  registrarPuerto(content: PuertoEntity) {
    return this.http.post<Puertos[]>(this.url_registrar, content);
  }

}
