export class Persona {
    codEmp: string;
    idPersona: string;
    idDocIde: string;
    tipoPersona: string;
    razonSoc: string;
    nombre: string;
    paterno: string;
    materno: string;
    direccion: string;
    telefono: string;
    email: string;
    usuario: string;
    flagCliente: number;
    flagProveedor: number;
    idGrupo: number;
    flagTrabajador: number;
    estado: string;
    tipoCobranza: number;
    indAprobacion: string;
    diasCredito: number;
    condiciones: string;
    docRequeridos: string;
    fecReg: string;
    usuarioMod: string;

}