import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';

// Model
import { Empresa } from '../../_model/publico/empresa';
import { FormasPago } from "../../_model/operaciones/formas_pago";

@Injectable({
  providedIn: 'root'
})
export class FormasPagoService {

  private url_listar_formas_pago = `${environment.HOST}/formas_pago/listar_formas`;
  private url_listar_por_empresa = `${environment.HOST}/formas_pago/listar_por_empresa`;
  private url_save_or_update = `${environment.HOST}/formas_pago/registrar`;

  constructor(private http: HttpClient) { }

  //lista las formas de pago activas
  listarFormasPago(content: Empresa) {
    return this.http.post<FormasPago[]>(this.url_listar_formas_pago, content);
  }
  //Lista todas las formas de pago de la empresa
  listarFormasPagoPorEmp(content: Empresa) {
    return this.http.post<FormasPago[]>(this.url_listar_por_empresa, content);
  }

  //Registra o actualiza la forma de pago
  saveOrUpdate(content: FormasPago) {
    return this.http.post<FormasPago[]>(this.url_save_or_update, content);
  }
}
