import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private fechaActual = new Date();
  constructor() { }

  obtenerFechaActual() {
    let anio = String(this.fechaActual.getFullYear());
    let mes = String(this.fechaActual.getMonth() + 1);
    let dia = String(this.fechaActual.getDate());

    if (dia.length == 1) {
      dia = `0${dia}`;
    }

    if (mes.length == 1) {
      mes = `0${mes}`;
    }

    let fecha = `${anio}-${mes}-${dia}`;

    return fecha;
  }

  obtenerFechaHoraActual() {
    let anio = String(this.fechaActual.getFullYear());
    let mes = String(this.fechaActual.getMonth() + 1);
    let dia = String(this.fechaActual.getDate());
    let horas = String(this.fechaActual.getHours());
    let minutos = String(this.fechaActual.getMinutes());
    let segundos = String(this.fechaActual.getSeconds());

    if (dia.length == 1) {
      dia = `0${dia}`;
    }

    if (mes.length == 1) {
      mes = `0${mes}`;
    }

    let fecha = `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

    return fecha;
  }

  //Convierte un valor recibido a string
  converToString(valor: any) {
    return String(valor);
  }
}
